<template>
	<SectionCard>
		<template #title>
			Process & Transfer Details
		</template>
		<template #body>
			<v-row
				dense
			>
				<v-col :cols="6">
					<Dropdown
						:view-only="securityMatrixView"
						label="Process Purpose"
						:value="processPurposeId"
						:items="processPurposeOptions"
						@input="$emit('update:processPurposeId', $event)"
					/>
				</v-col>
			</v-row>
			<v-divider />
			<v-row dense>
				<v-col :cols="6">
					<Dropdown
						:view-only="securityMatrixView"
						label="Source System"
						:value="sourceSystemId"
						:items="systemOptions"
						@input="$emit('update:sourceSystemId', $event)"
					/>
				</v-col>
				<v-col
					v-if="sourceSystemContainsCassieSystemTypeId && !sourceVueCsPortal"
					:cols="6"
				>
					<TextField
						:view-only="securityMatrixView"
						:value="sourceCassieSubSystemInstanceId"
						:label="sourceSystemContainsCassieSystemTypeLabel"
						type="number"
						@input="$emit('update:sourceCassieSubSystemInstanceId', $event)"
					/>
				</v-col>
			</v-row>
			<v-divider />
			<v-row dense>
				<v-col :cols="6">
					<Dropdown
						:view-only="securityMatrixView"
						label="Destination System"
						:value="destinationSystemId"
						:items="systemOptions"
						@input="$emit('update:destinationSystemId', $event)"
					/>
				</v-col>
				<v-col
					v-if="destinationSystemContainsCassieSystemTypeId && !destinationVueCsPortal"
					:cols="6"
				>
					<TextField
						:view-only="securityMatrixView"
						:value="destinationCassieSubSystemInstanceId"
						:label="destinationSystemContainsCassieSystemTypeLabel"
						type="number"
						@input="$emit('update:destinationCassieSubSystemInstanceId', $event)"
					/>
				</v-col>
			</v-row>
			<v-divider />
			<v-row dense>
				<v-col :cols="6">
					<Dropdown
						:view-only="securityMatrixView"
						label="Security Measures"
						:value="securityMeasureTypeId"
						:items="securityMeasureOptions"
						@input="$emit('update:securityMeasureTypeId', $event)"
					/>
				</v-col>
				<v-col :cols="6">
					<Dropdown
						:view-only="securityMatrixView"
						label="Schedule"
						:value="scheduleTypeId"
						:items="scheduleOptions"
						@input="$emit('update:scheduleTypeId', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
export default {
	components: { TextField, Dropdown, SectionCard },
	props: {
		securityMatrixView: Boolean,
		processPurposeId: Number,
		sourceSystemId: Number,
		sourceCassieSubSystemInstanceId: [String, Number],
		destinationSystemId: Number,
		destinationCassieSubSystemInstanceId: [String, Number],
		destinationCassieSubSystemId: Number,
		destinationCassieSubSystemName: String,
		securityMeasureTypeId: Number,
		scheduleTypeId: Number,
		cassieTypeId: Number,
		lookUpData: Object
	},
	computed: {
		systemOptions () {
			return this.lookUpData.systems.map(({ systemId, systemName, cassieTypeId }) => ({ value: systemId, text: systemName, cassieTypeId: cassieTypeId }))
		},
		processPurposeOptions () {
			return this.lookUpData.processPurposes.map(({ processPurposeId, processPurposeName }) => ({ value: processPurposeId, text: processPurposeName }))
		},
		sourceSystemContainsCassieSystemTypeId () {
			let cassieTypeId = null
			if (this.sourceSystemId) {
				cassieTypeId = this.systemOptions.find(({ value }) => value === this.sourceSystemId)?.cassieTypeId
				const matchingSystem = this.systemOptions.find(({ value }) => value === this.sourceSystemId)
				if (matchingSystem) {
					cassieTypeId = matchingSystem
				}
			}

			if (cassieTypeId) {
				return !!cassieTypeId
			} else {
				return false
			}
		},

		sourceSystemContainsCassieSystemTypeLabel () {
			if (this.sourceSystemId) {
				const selectedSystemCassieSystemId = this.systemOptions.find(({ value }) => value === this.sourceSystemId).cassieTypeId
				const label = this.lookUpData.cassieSubSystemTypes.find(({ cassieSubSystemTypeId }) => cassieSubSystemTypeId === selectedSystemCassieSystemId).cassieSubSystemTypeName
				return `${label} ID`
			} else {
				return ''
			}
		},
		destinationSystemContainsCassieSystemTypeId () {
			let cassieTypeId = null
			if (this.destinationSystemId) {
				const matchingSystem = this.systemOptions.find(({ value }) => value === this.destinationSystemId)
				if (matchingSystem) {
					cassieTypeId = matchingSystem
				}
			}

			if (cassieTypeId) {
				return !!cassieTypeId
			} else {
				return false
			}
		},
		destinationSystemContainsCassieSystemTypeLabel () {
			if (this.destinationSystemId) {
				const selectedSystemCassieSystemId = this.systemOptions.find(({ value }) => value === this.destinationSystemId).cassieTypeId
				const label = this.lookUpData.cassieSubSystemTypes.find(({ cassieSubSystemTypeId }) => cassieSubSystemTypeId === selectedSystemCassieSystemId).cassieSubSystemTypeName
				return `${label} ID`
			} else {
				return ''
			}
		},
		sourceVueCsPortal () {
			const isVueCsPortal = this.sourceSystemContainsCassieSystemTypeLabel === 'Vue CS Portal ID'
			return isVueCsPortal
		},
		destinationVueCsPortal () {
			const isVueCsPortal = this.destinationSystemContainsCassieSystemTypeLabel === 'Vue CS Portal ID'
			return isVueCsPortal
		},
		securityMeasureOptions () {
			return this.lookUpData.securityMeasureTypes.map(({ securityMeasureTypeId, securityMeasureTypeName }) => ({ value: securityMeasureTypeId, text: securityMeasureTypeName }))
		},
		scheduleOptions () {
			return this.lookUpData.scheduleTypes.map(({ scheduleTypeId, scheduleTypeName }) => ({ value: scheduleTypeId, text: scheduleTypeName }))
		}
	}
}
</script>
