import { configApi } from '../http-client.js'

export const getCitiesByCountryId = countryId => configApi.get(`/api/Ropa/Cities?Country=${countryId}`).then(({ data }) => {
	return data.countries[0].cities.sort((a, b) => {
		if (a.cityName.toLowerCase() < b.cityName.toLowerCase()) {
			return -1
		} else {
			return 1
		}
	})
})
