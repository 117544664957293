<template>
	<ViewLayout>
		<template #header-title>
			{{ headerTitle }}
		</template>
		<template #header-caption>
			{{ headerCaption }}
		</template>
		<template #content>
			<ValidationForm
				#default="{ handleSubmit }"
				class="cassie-vertical-md"
			>
				<div v-if="securityMatrixView">
					<v-row>
						<v-col :cols="6">
							<ProcessMap
								:items="[process]"
							/>
						</v-col>
						<v-col :cols="6">
							<ProcessChart
								:point-radius="true"
								:items="[process]"
								:chart-api-params="{
									dateFrom: dateFrom,
									dateTo: dateTo,
									processes: [process.processId]
								}"
								:date-from="dateFrom"
								:date-to="dateTo"
							/>
						</v-col>
					</v-row>
				</div>
				<GeneralDetailsCard
					:security-matrix-view="securityMatrixView"
					:name.sync="process.name"
					:description.sync="process.description"
					:brand-id.sync="process.brandId"
					:city-id.sync="process.cityId"
					:country-id.sync="process.countryId"
					:owner-id.sync="process.ownerId"
					:data-transfer-type-id.sync="process.dataTransferTypeId"
					:is-special-category-data.sync="process.isSpecialCategoryData"
					:is-cassie-process.sync="process.isCassieProcess"
					:is-automated-decisions.sync="process.isAutomatedDecisions"
					:look-up-data="lookUpData"
				/>
				<ComplianceDetailsCard
					:security-matrix-view="securityMatrixView"
					:brand-id.sync="process.brandId"
					:legal-basis-id.sync="process.legalBasisId"
					:is-d-p-a-completed.sync="process.isDPACompleted"
					:is-article30-required.sync="process.isArticle30Required"
					:dpo-contact-id.sync="process.dpoContactId"
					:internal-contact-id.sync="process.internalContactId"
					:special-category-processing-description.sync="process.specialCategoryProcessingDescription"
					:pii-categories.sync="process.piiCategories"
					:special-pii-categories.sync="process.specialPiiCategories"
					:data-subject-categories.sync="process.dataSubjectCategories"
					:look-up-data="lookUpData"
					:is-special-category-data="process.isSpecialCategoryData"
					:legal-basis-guid="process.legalBasisFileGUID"
					:article-30-guid="process.article30FileGUID"
					:dpa-file-guid="process.dpaFileGUID"
					@update:legalBasisFileGUID="updateFileGuid('legalBasisFileGUID', $event)"
					@update:article30FileGUID="updateFileGuid('article30FileGUID', $event)"
					@update:dpaFileGUID="updateFileGuid('dpaFileGUID', $event)"
				/>
				<ProcessAndTransferDetailsCard
					:security-matrix-view="securityMatrixView"
					:process-purpose-id.sync="process.processPurposeId"
					:source-system-id.sync="process.sourceSystemId"
					:source-cassie-sub-system-instance-id.sync="process.sourceCassieSubSystemInstanceId"
					:destination-system-id.sync="process.destinationSystemId"
					:destination-cassie-sub-system-instance-id.sync="process.destinationCassieSubSystemInstanceId"
					:destination-cassie-sub-system-id.sync="process.destinationCassieSubSystemId"
					:destination-cassie-sub-system-name.sync="process.destinationCassieSubSystemName"
					:security-measure-type-id.sync="process.securityMeasureTypeId"
					:schedule-type-id.sync="process.scheduleTypeId"
					:cassie--type-id="process.cassieTypeId"
					:look-up-data="lookUpData"
				/>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="navigateToOverview">
							Back to overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="!securityMatrixView"
							@click="handleSubmit(submit)"
						>
							{{ isEdit ? 'Save' : 'Create' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</ValidationForm>
		</template>
	</ViewLayout>
</template>

<script>
import { sub, formatISO } from 'date-fns'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import GeneralDetailsCard from './general-details-card.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import { PROCESSING_PROCESSES, PROCESSING_SECURITY_MATRIX } from '../../../../router/route-names.js'
import { getProcessesAndLookupData, upsertProcess } from '../../../../../../shared/utils/api/processing.js'
import ProcessAndTransferDetailsCard from './process-and-transfer-details-card.vue'
import ComplianceDetailsCard from './compliance-details-card.vue'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import ProcessMap from '../process-map/process-map.vue'
import ProcessChart from '../process-chart/process-chart.vue'
export default {
	components: {
		ComplianceDetailsCard,
		ProcessAndTransferDetailsCard,
		ValidationForm,
		PrimaryActionButton,
		SecondaryActionButton,
		PageActionRow,
		GeneralDetailsCard,
		ViewLayout,
		ProcessMap,
		ProcessChart
	},
	props: {
		processToEdit: Object,
		processSecurityMatrix: Object
	},
	data () {
		return {
			process: JSON.parse(JSON.stringify(this.processToEdit || this.processSecurityMatrix || {
				name: '',
				description: '',
				brandId: null,
				countryId: null,
				cityId: null,
				ownerId: null,
				dataTransferTypeId: null,
				isSpecialCategoryData: true,
				isCassieProcess: true,
				isAutomatedDecisions: true,
				sourceSystemId: null,
				sourceCassieSubSystemInstanceId: null,
				processPurposeId: null,
				destinationSystemId: null,
				destinationCassieSubSystemInstanceId: null,
				destinationCassieSubSystemId: null,
				destinationCassieSubSystemName: null,
				securityMeasureTypeId: null,
				scheduleTypeId: null,
				legalBasisId: null,
				isDPACompleted: null,
				isArticle30Required: null,
				dpoContactId: null,
				internalContactId: null,
				specialCategoryProcessingDescription: null,
				piiCategories: [],
				specialPiiCategories: [],
				dataSubjectCategories: [],
				legalBasisFileGUID: null,
				article30FileGUID: null,
				dpaFileGUID: null
			})),
			lookUpData: {
				processOwners: [],
				dataTransferTypes: [],
				systems: [],
				cassieSubSystemTypes: [],
				securityMeasureTypes: [],
				scheduleTypes: [],
				legalBasis: [],
				contacts: [],
				dataSubjectCategories: [],
				piiCategories: [],
				specialPiiCategories: [],
				processPurposes: []
			},
			dateFrom: formatISO(sub(new Date(), { weeks: 2 }), { representation: 'date' }),
			dateTo: formatISO(new Date(), { representation: 'date' })
		}
	},
	computed: {
		isEdit () {
			return Boolean(this.processToEdit)
		},
		securityMatrixView () {
			return Boolean(this.processSecurityMatrix)
		},
		headerTitle () {
			if (this.securityMatrixView) {
				return this.processSecurityMatrix.name
			} else {
				return this.isEdit ? 'Edit Process' : 'Create Process'
			}
		},
		headerCaption () {
			if (this.securityMatrixView) {
				return null
			} else {
				return this.isEdit ? 'Edit an existing process' : 'Add a new process'
			}
		}
	},
	async created () {
		const { data: { lookUpData } } = await getProcessesAndLookupData()
		this.lookUpData = lookUpData
	},
	methods: {
		navigateToOverview () {
			this.$router.push({
				name: this.securityMatrixView ? PROCESSING_SECURITY_MATRIX : PROCESSING_PROCESSES
			})
		},
		async submit () {
			await upsertProcess(this.process)
			showSnackbar(this.isEdit ? 'This process has been updated' : 'You have created a new process')
			setTimeout(this.navigateToOverview, 500)
		},
		updateFileGuid (property, value) {
			this.process[property] = value
		}
	}
}
</script>
