<template>
	<LineChart
		:chart-data="chartData"
		:chart-options="chartOptions"
	/>
</template>

<script>
import LineChart from '../../../../../../shared/components/chart/line-chart.vue'
import { getProcessCounts } from '../../../../../../shared/utils/api/processing.js'

export default {
	components: { LineChart },
	props: {
		items: Array,
		pointRadius: {
			type: Boolean,
			default: false
		},
		chartApiParams: Object,
		dateFrom: String,
		dateTo: String
	},
	data () {
		return {
			processesChartData: [],
			chartOptions: {
				responsive: true,
				maintainAspectRatio: false
			}
		}
	},
	computed: {
		filteredProcesses () {
			const processIds = this.items.map(({ processId }) => processId)
			return this.processesChartData.filter(({ processId }) => processIds.includes(processId))
		},
		modifiedProcesses () {
			const colorsMap = new Map(this.items.map(i => [i.processId, i.processColor]))

			return this.filteredProcesses.map(process => ({
				...process,
				processColor: colorsMap.get(process.processId)
			}))
		},
		chartData () {
			const pointRadius = this.pointRadius ? undefined : 0

			const labels = {}
			const datasets = []

			this.modifiedProcesses.forEach(process => {
				const { processCounts, processName, processColor } = process

				const data = []

				processCounts.forEach(obj => {
					const [label, value] = Object.entries(obj).flat()

					data.push(value)
					labels[label] = null
				})

				datasets.push({
					borderColor: processColor,
					label: processName,
					data,
					pointRadius
				})
			})
			return {
				labels: Object.keys(labels),
				datasets
			}
		}
	},
	watch: {
		chartApiParams () {
			this.loadProcessCounts()
		}
	},
	async created () {
		this.loadProcessCounts()
	},
	methods: {
		async loadProcessCounts () {
			const { data: { processes } } = await getProcessCounts(this.chartApiParams.processes, this.chartApiParams.dateFrom, this.chartApiParams.dateTo)
			this.processesChartData = processes
		}
	}
}
</script>
