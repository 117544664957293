<template>
	<SectionCard>
		<template #title>
			Compliance Details
		</template>
		<template #body>
			<div v-if="!securityMatrixView">
				<v-row dense>
					<v-col :cols="6">
						<Dropdown
							:value="legalBasisId"
							:items="legalBasisOptions"
							label="What is the legal basis of processing? *"
							rules="required"
							@input="$emit('update:legalBasisId', $event)"
						/>
					</v-col>
					<v-col :cols="6">
						<FileInput
							label="Legal basis documentation"
							:accept="allowedFileTypes"
							@selectFile="selectFile"
							@uploadFile="uploadRopaDocument('legalBasis')"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col :cols="6">
						<Dropdown
							:value="isDPACompleted"
							:items="[{ value: true, text: 'Yes'}, { value: false, text: 'No'}]"
							label="Has a DPIA been completed? *"
							rules="required"
							@input="$emit('update:isDPACompleted', $event)"
						/>
					</v-col>
					<v-col
						v-if="isDPACompleted"
						:cols="6"
					>
						<FileInput
							label="DPIA documentation"
							:accept="allowedFileTypes"
							@selectFile="selectFile"
							@uploadFile="uploadRopaDocument('dpa')"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col :cols="6">
						<Dropdown
							:value="isArticle30Required"
							:items="[{ value: true, text: 'Yes'}, { value: false, text: 'No'}]"
							label="Is there an Article 30 requirement? *"
							rules="required"
							@input="$emit('update:isArticle30Required', $event)"
						/>
					</v-col>
					<v-col
						v-if="isArticle30Required"
						:cols="6"
					>
						<FileInput
							label="Article 30 documentation"
							:accept="allowedFileTypes"
							@selectFile="selectFile"
							@uploadFile="uploadRopaDocument('article30')"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col :cols="6">
						<Dropdown
							:value="dpoContactId"
							:items="dpoContactOptions"
							label="Who is the Internal Data Protection Officer (DPO)?"
							@input="$emit('update:dpoContactId', $event)"
						/>
					</v-col>
					<v-col :cols="6">
						<Dropdown
							:value="internalContactId"
							:items="internalContactOptions"
							label="Who is the Internal Process Contact?"
							@input="$emit('update:internalContactId', $event)"
						/>
					</v-col>
				</v-row>
				<v-divider class="my-4" />
				<v-row dense>
					<v-col :cols="6">
						<OptionSelector
							:selected="dataSubjectCategories"
							:options="dataSubjectCategoryOptions"
							dropdown-label="Data Subject Categories Included"
							entity-type="Data Subject Category Name"
							@update:selected="$emit('update:dataSubjectCategories', $event)"
						/>
					</v-col>
					<v-col :cols="6">
						<OptionSelector
							:selected="piiCategories"
							:options="piiCategoryOptions"
							dropdown-label="PII Categories Included"
							entity-type="PII Category Name"
							@update:selected="$emit('update:piiCategories', $event)"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col :cols="6">
						<OptionSelector
							:selected="specialPiiCategories"
							:options="specialPiiCategoryOptions"
							dropdown-label="Special PII Categories Included"
							entity-type="Special PII Category Name"
							@update:selected="$emit('update:specialPiiCategories', $event)"
						/>
					</v-col>
				</v-row>
				<template v-if="isSpecialCategoryData">
					<v-divider />
					<TextArea
						:value="specialCategoryProcessingDescription"
						label="Conditions for Special Category Processing"
						@input="$emit('update:specialCategoryProcessingDescription', $event)"
					/>
				</template>
			</div>
			<div v-else>
				<v-row dense>
					<v-col :cols="legalBasisGuid ? 5 : 6">
						<Dropdown
							view-only
							:value="legalBasisId"
							:items="legalBasisOptions"
							label="What is the legal basis of processing?"
						/>
					</v-col>
					<v-col
						v-if="legalBasisGuid"
						:cols="1"
					>
						<IconButton>
							mdi-download
						</IconButton>
					</v-col>
					<v-col :cols="6">
						<Dropdown
							view-only
							:value="dpoContactId"
							:items="dpoContactOptions"
							label="Who is the Internal Data Protection Officer (DPO)?"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col :cols="dpaFileGuid ? 5 : 6">
						<Dropdown
							view-only
							:value="isDPACompleted"
							:items="[{ value: true, text: 'Yes'}, { value: false, text: 'No'}]"
							label="Has a DPIA been completed?"
						/>
					</v-col>
					<v-col
						v-if="dpaFileGuid"
						:cols="1"
					>
						<IconButton>
							mdi-download
						</IconButton>
					</v-col>
					<v-col :cols="6">
						<Dropdown
							view-only
							:value="internalContactId"
							:items="internalContactOptions"
							label="Who is the Internal Process Contact?"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col :cols="article30Guid ? 5 : 6">
						<Dropdown
							view-only
							:value="isArticle30Required"
							:items="[{ value: true, text: 'Yes'}, { value: false, text: 'No'}]"
							label="Is there an Article 30 requirement?"
						/>
					</v-col>
					<v-col
						v-if="article30Guid"
						:cols="1"
					>
						<IconButton>
							mdi-download
						</IconButton>
					</v-col>
				</v-row>
				<v-divider class="my-4" />
				<v-row dense>
					<v-col :cols="6">
						<v-simple-table
							dense
							light
						>
							<template #default>
								<thead>
									<tr>
										<th
											scope="col"
											class="text-left"
										>
											Data Subject Categories
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="category in securityMatrixDataSubjectCategories"
										:key="category.value"
									>
										<td>
											{{ category.text }}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
					<v-col :cols="6">
						<v-simple-table
							dense
							light
						>
							<template #default>
								<thead>
									<tr>
										<th
											scope="col"
											class="text-left"
										>
											Pii Categories
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="category in securityMatrixPiiCategories"
										:key="category.value"
									>
										<td>
											{{ category.text }}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
				</v-row>
				<v-row>
					<v-col :cols="6">
						<v-simple-table
							dense
							light
						>
							<template #default>
								<thead>
									<tr>
										<th
											scope="col"
											class="text-left"
										>
											Special Pii Categories
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="category in securityMatrixSpecialPiiCategories"
										:key="category.value"
									>
										<td>
											{{ category.text }}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
				</v-row>
			</div>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import FileInput from '../../../../../../shared/components/file-input.vue'
import OptionSelector from '../../../../../../shared/components/option-selector.vue'
import TextArea from '../../../../../../shared/components/textarea.vue'
import { uploadRopaFile } from '../../../../../../shared/utils/api/processing.js'
import IconButton from '../../../../../../shared/components/icon-button.vue'
export default {
	components: { TextArea, OptionSelector, FileInput, Dropdown, SectionCard, IconButton },
	props: {
		securityMatrixView: Boolean,
		legalBasisGuid: String,
		article30Guid: String,
		dpaFileGuid: String,
		brandId: Number,
		lookUpData: Object,
		isSpecialCategoryData: Boolean,
		legalBasisId: Number,
		isDPACompleted: Boolean,
		isArticle30Required: Boolean,
		dpoContactId: Number,
		internalContactId: Number,
		specialCategoryProcessingDescription: String,
		piiCategories: Array,
		specialPiiCategories: Array,
		dataSubjectCategories: Array
	},
	setup () {
		return {
			uploadRopaFile
		}
	},
	data () {
		return {
			selectedFile: null
		}
	},
	computed: {
		legalBasisOptions () {
			return this.lookUpData.legalBasis.map(({ legalBasisId, legalBasisName }) => ({ value: legalBasisId, text: legalBasisName }))
		},
		dpoContactOptions () {
			return this.contactOptions.filter(({ isExternal, isDPO }) => !isExternal && isDPO)
		},
		internalContactOptions () {
			return this.contactOptions.filter(({ isExternal }) => isExternal === false)
		},
		contactOptions () {
			return this.lookUpData.contacts.map(({ contactId, contactFullName, isDPO, isExternal }) => ({ value: contactId, text: contactFullName, isDPO, isExternal }))
		},
		dataSubjectCategoryOptions () {
			return this.lookUpData.dataSubjectCategories.map(({ dataSubjectCategoryId, dataSubjectCategoryName }) => ({ value: dataSubjectCategoryId, text: dataSubjectCategoryName }))
		},
		specialPiiCategoryOptions () {
			return this.lookUpData.specialPiiCategories.map(({ specialPiiCategoryId, specialPiiCategoryName }) => ({ value: specialPiiCategoryId, text: specialPiiCategoryName }))
		},
		piiCategoryOptions () {
			return this.lookUpData.piiCategories.map(({ piiCategoryId, piiCategoryName }) => ({ value: piiCategoryId, text: piiCategoryName }))
		},
		allowedFileTypes () {
			return '.bmp, .csv, .doc, .docx, .jpeg, .jpg, .odp, .ods, .odt, .png, .pdf, .ppt, .rtf, .svg, .tiff, .tiff, .txt, .vsd, .xls, .xlsx'
		},
		securityMatrixDataSubjectCategories () {
			return this.dataSubjectCategoryOptions.filter(option => this.dataSubjectCategories.includes(option.value)).map(category => {
				return category
			})
		},
		securityMatrixPiiCategories () {
			return this.piiCategoryOptions.filter(option => this.piiCategories.includes(option.value)).map(category => {
				return category
			})
		},
		securityMatrixSpecialPiiCategories () {
			return this.specialPiiCategoryOptions.filter(option => this.specialPiiCategories.includes(option.value)).map(category => {
				return category
			})
		}
	},
	methods: {
		selectFile (file) {
			this.selectedFile = file
		},
		async uploadRopaDocument (fileType) {
			const ropaFile = new FormData()
			ropaFile.append('RopaFile', this.selectedFile)
			ropaFile.append('BrandId', this.brandId)
			await uploadRopaFile(ropaFile).then(response => {
				const guid = response.data.guid
				this.setGuidInProcess(fileType, guid)
			})
			this.selectedFile = null
		},
		setGuidInProcess (fileType, guid) {
			if (fileType === 'article30') {
				this.$emit('update:article30FileGUID', guid)
			} else if (fileType === 'legalBasis') {
				this.$emit('update:legalBasisFileGUID', guid)
			} else {
				this.$emit('update:dpaFileGUID', guid)
			}
		}
	}
}
</script>
