<template>
	<div class="d-flex cassie-horizontal-md">
		<Dropdown
			:value="countryId"
			:label="`Country${required? ' *' : ''}`"
			:rules="required ? 'required' : undefined"
			:items="countries"
			:disabled="disabled"
			:view-only="viewOnly"
			item-text="countryName"
			item-value="countryId"
			style="flex: 1;"
			@input="updateCountryId"
		/>
		<Dropdown
			v-if="countryId"
			:value="cityId"
			:label="`City${required? ' *' : ''}`"
			:rules="required ? 'required' : undefined"
			:items="cities"
			:disabled="disabled"
			:view-only="viewOnly"
			item-text="cityName"
			item-value="cityId"
			style="flex: 1;"
			@input="$emit('update:cityId', $event)"
		/>
	</div>
</template>

<script>
import Dropdown from '../../../../../shared/components/dropdown.vue'
import { getCitiesByCountryId } from '../../../../../shared/utils/api/cities.js'
export default {
	components: { Dropdown },
	props: {
		required: Boolean,
		countries: Array,
		countryId: Number,
		cityId: Number,
		disabled: Boolean,
		viewOnly: Boolean
	},
	data () {
		return {
			cities: []
		}
	},
	created () {
		if (this.countryId) this.loadCities(this.countryId)
	},
	methods: {
		updateCountryId (countryId) {
			this.$emit('update:countryId', countryId)
			this.loadCities(countryId)
		},
		async loadCities (countryId) {
			const cities = await getCitiesByCountryId(countryId)
			this.cities = cities
		}
	}
}
</script>
